/* eslint-disable complexity */
import { IProfile } from 'services/models/IProfile';
import { IMyAccountFormModel } from 'models/MyAccountFormModel';
import { parseString } from 'utils/commonUtils';

export function parseProfileModelToForm(
  profileData: IProfile
): IMyAccountFormModel {
  const {
    firstName,
    lastName,
    email,
    primaryPhone,
    profilePicture,
    settings,
    officerInfo,
    middleName,
    suffix,
    uuid
  } = profileData;

  return {
    first_name: parseString(firstName),
    last_name: parseString(lastName),
    nmls: parseString(officerInfo?.nmls),
    middle_name: parseString(middleName),
    suffix: parseString(suffix),
    email: parseString(email),
    phone_primary: parseString(primaryPhone),
    image: profilePicture,
    settings,
    secondaryEmail: officerInfo?.secondaryEmail || null,
    uuid,
    receive_welcome_email: settings?.sendWelcomeEmail ?? false,
    enable_plaid: settings?.plaidEnabled ?? false,
    enable_credit_score: settings?.isCreditScoreEnabled ?? false,
    enable_du: settings?.isDuEnabled ?? false,
    enable_rate_quote: settings?.isRateQuoteEnabled ?? false,
    enable_du_prequal: settings?.duAutomaticPreApproval ?? false,
    loans_notifications: settings?.loansNotifications ?? false,
    tasks_notifications: settings?.tasksNotifications ?? false,
    documents_notifications: settings?.documentsNotifications ?? false,
    messages_notifications: settings?.messagesNotifications ?? false,
    sms_notifications: settings?.smsNotifications ?? false
  };
}

export function parseMyAccountModelToBody(
  profileData: IMyAccountFormModel
): Partial<IProfile> {
  const {
    first_name,
    last_name,
    email,
    phone_primary,
    settings,
    secondaryEmail,
    middle_name,
    suffix,
    receive_welcome_email,
    enable_plaid,
    enable_du,
    enable_credit_score,
    enable_rate_quote,
    enable_du_prequal,
    loans_notifications,
    tasks_notifications,
    documents_notifications,
    messages_notifications,
    sms_notifications
  } = profileData;

  return {
    firstName: first_name,
    lastName: last_name,
    middleName: middle_name,
    suffix: suffix,
    email: email,
    primaryPhone: phone_primary,
    settings: {
      ...settings,
      sendWelcomeEmail: receive_welcome_email,
      plaidEnabled: enable_plaid,
      isDuEnabled: enable_du,
      isCreditScoreEnabled: enable_credit_score,
      isRateQuoteEnabled: enable_rate_quote,
      duAutomaticPreApproval: enable_du_prequal,
      loansNotifications: loans_notifications,
      tasksNotifications: tasks_notifications,
      documentsNotifications: documents_notifications,
      messagesNotifications: messages_notifications,
      smsNotifications: sms_notifications
    },
    secondaryEmail: secondaryEmail
  };
}
